class ToolbarSearch {
  constructor() {
    this.searchOpened = this.searchOpened.bind(this);
    this.searchClosed = this.searchClosed.bind(this);
    this.searchShow = this.searchShow.bind(this);
    this.searchHide = this.searchHide.bind(this);
    this.searchCleared = this.searchCleared.bind(this);
    this.navSearchTarget = '.pc-SearchTarget';
    this.initBehavior();
  }

  el() {
    return $('.pc-ToolBar-search');
  }

  search() {
    return this.el().find('.pc-Search');
  }

  // Private

  initBehavior() {
    $(document).on(
      'click',
      '.pc-ToolBar-search .pc-Search-formClose',
      this.searchClosed
    );
    $(document).on(
      'click',
      '.pc-ToolBar-search .pc-Search-clear',
      this.searchCleared
    );
    $(document).on(
      'click',
      '.pc-OverlayElement, .pc-Search-close',
      this.searchClosed
    );
    $(document).on('submit', '.pc-Search-form', this.searchHide);
    $(window).on(
      'beforeunload',
      null,
      {skipPreventDefault: true, searchFocus: false},
      this.searchClosed
    );
    return enquire.register(StyleBitz.Breakpoints.md, this.searchClosed);
  }

  // Private (event handlers)

  searchOpened(e) {
    if (!($(window).width() < 700)) {
      return;
    }
    if ($(e.target).is('.pc-Search-formClose')) {
      return;
    }
    $(document).trigger('pcToolbar.searchOpened', e);
    $(this.navSearchTarget).addClass('active');
    this.el().siblings('.pc-ToolBar-header').hide();
    this.searchShow(e);
    return this.overlayShow(e);
  }

  searchClosed(e) {
    $(document).trigger('pcToolbar.searchClosed', e);
    $(this.navSearchTarget).removeClass('active');
    this.el().siblings('.pc-ToolBar-header').show();
    $('.pc-ToolBar-section').eq(1).show();
    this.el().removeClass('active');
    this.overlayHide(e);
    if (e) {
      this.searchCleared(e);
    }
    return undefined;
  }

  searchShow(e) {
    this.el().addClass('active');
    this.showSearchClose(e);

    return this.search()
      .find('.pc-Search-form')
      .fadeIn()
      .css('display', 'flex')
      .end()
      .find('.pc-Search-inputArea input[type=text]')
      .focus();
  }

  showSearchClose(e) {
    return this.search().find('.pc-Search-close').show();
  }

  searchHide(e) {
    this.search().find('.pc-Search-inputArea input[type=text]').blur();
    $('.pc-Search-close').hide();
    if (!($(window).width() < 700)) {
      return;
    }
    this.el().removeClass('active');
    return this.search()
      .find('.pc-Search-form')
      .hide()
      .end()
      .find('.pc-Search-target')
      .fadeIn('slow');
  }

  searchCleared(e) {
    if (e && (!e.data || e.data.skipPreventDefault !== true)) {
      e.preventDefault();
    }
    if (e && e.data && e.data.searchFocus == false) {
      return this.search()
        .find('.pc-Search-inputArea input[type=text]')
        .val('');
    } else {
      return this.search()
        .find('.pc-Search-inputArea input[type=text]')
        .val('')
        .focus();
    }
  }

  overlayShow() {
    return ProductCatalog.Toolbar.next().addClass('pc-OverlayElement');
  }

  overlayHide() {
    return ProductCatalog.Toolbar.next().removeClass('pc-OverlayElement');
  }
}

jQuery(() => (ProductCatalog.ToolbarSearch = new ToolbarSearch()));
