window.dataLayer = window.dataLayer || [];

ProductCatalog.gtmEvent = (eventName, key, value) =>
  window.dataLayer.push({
    event: `ci.catalog.${eventName}`,
    [`ci.catalog.${key}`]: value
  });

ProductCatalog.gtmEcomEvent = (eventName, eventPayload, eventAttributes = {}) => {
  window.dataLayer.push({
    ecommerce: null
  });
  window.dataLayer.push({
    event: eventName,
    ecommerce: eventPayload,
    ...eventAttributes
  });
};
