import jQuery from 'jquery';
import {v4 as uuidv4} from 'uuid';
import Cookies from 'js-cookie';

global.$ = jQuery;
global.jQuery = jQuery;
// Used by toolbar and a few other JS components
const Waypoint = require('waypoints/lib/jquery.waypoints');
require('intersection-observer');
require('url-polyfill');
import 'src/product_catalog';
import 'src/shared/analytics';
import 'src/shared/iframe';
import 'src/shared/gtm';
import 'src/shared/image_lazy_loader';
import 'src/shared/toolbar';
import 'src/shared/toolbar/shopby';
import 'src/shared/toolbar/search';
import 'src/shared/ujspopup';
import 'src/shared/faqs';
import 'src/shared/overlay';
import 'src/shared/overlay_dropdown';
import 'src/shared/post_message';
import 'src/shared/local_storage';

const sessionToken = Cookies.get('session_token');
const oeToken = Cookies.get('oe');
if(!oeToken && !sessionToken) {
  let uuid;
  if(crypto && crypto.randomUUID) {
    uuid = crypto.randomUUID()
  } else {
    uuid = uuidv4();
  }
  Cookies.set('session_token', uuid);
}
